/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* For AlpineJS */
[x-cloak] {
  display: none !important;
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/inter-v3-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/inter-v3-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* inter-500 - latin */
@font-face {
  font-family: 'Inter';
font-style: normal;
  font-weight: 500;
  src: local(''),
       url('/fonts/inter-v3-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/inter-v3-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/inter-v3-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/inter-v3-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}


.form-label {
  @apply block mb-1 text-lg font-medium;
}

.form-input,
.form-textarea,
.form-dropdown-button,
.form-dropdown-content  {
  @apply px-4 py-2 block w-full border border-gray-300 rounded-md shadow-sm sm:text-lg bg-white text-left;
}

.form-textarea {
  @apply h-48;
}

.form-checkbox-group {
  @apply flex w-fit items-center py-1
}
.form-checkbox-group .form-checkbox {
  @apply mr-2 rounded-sm border-gray-600;
}


.form-submit {
  @apply inline-flex justify-center w-full px-5 py-3 text-xl font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
}
